.App {
  text-align: center;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: center;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position:absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 4vh;
}

a {
    color: #ff0048!important;
    text-decoration: none!important;
}

a:hover {
    color: #fff04d!important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-group-item {
  color: #57ffca!important;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0)!important;
  border: none!important;
}

.list-group-item:hover {
    color: #fff04d!important;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0)!important;
    border: none!important;
  }

.list-group-item.active {
  background-color: rgba(0, 0, 0, 0)!important;
  border: none!important;
}

.backgroundDiv {
  z-index: 0;
  height: 100vh;
  left: -450px;
}

.image {
  height: 100vh;
  margin-left: 20vh;
  z-index: 1;
}

.navDiv {
    margin-top: 2vh;
}

.navDiv h1 {
  font-family: 'Bangers', cursive;
  font-size: calc(25px + 5vmin);
}

@media only screen and (min-aspect-ratio: 95/60) {
  .image {
    margin-left: 0;
  }
}

.stepper {
    background-color: rgba(255, 255, 255, 0)!important;
}